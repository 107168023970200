import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
import { Playground, Props } from 'docz';
import { useState } from 'react';
import { DateTimePicker } from '@hse-design/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "datetimepicker"
    }}>{`DateTimePicker`}</h1>
    <ComponentLinks storybook={'/?path=/story/calendar-calendar--date-time-picker'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=8162%3A1775'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`DateTimePicker – компонент для выбора даты и времени. Компонент включает в себя `}<a parentName="p" {...{
        "href": "/src-components-date-picker"
      }}>{`DatePicker`}</a>{` `}<a parentName="p" {...{
        "href": "/src-components-single-time-picker"
      }}>{`SingleTimePicker`}</a>{`.`}</p>
    <p>{`DateTimePicker контролируемый компонент: для его работы следует передать пропы `}<inlineCode parentName="p">{`value`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState(new Date())\n  return <DateTimePicker value={value} onChange={setValue} />\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      Playground,
      Props,
      useState,
      DateTimePicker,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const [value, setValue] = useState(new Date());
        return <DateTimePicker value={value} onChange={setValue} mdxType="DateTimePicker" />;
      }}
    </Playground>
    <FigmaEmbed node='?node-id=8162%3A1775' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5708' height={1400} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5630' height={1400} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=9288%3A5736' mdxType="FigmaEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      